import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Card from '@material-ui/core/Card';

import CheckCircle from '@material-ui/icons/CheckCircle';

import fontSize from '../../assets/fontSize';
import colors from '../../assets/colors';

const Container = styled.div`
  padding: 2rem 0;
  margin-top: 3rem;
`;
const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
`;
const Title = styled.h3`
  margin: 0;
  font-size: ${fontSize.oneHalfRem};
  text-align: center;

  @media screen and (min-width: 730px) {
    font-size: ${fontSize.twoRem};
  }
`;
const PerksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5rem;
  margin-top: 2rem;
`;
const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media screen and (min-width: 730px) {
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    gap: 3rem;
  }
`;
const PerkListContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 1rem;
`;
const PerkTitle = styled.h5`
  margin: 0;
  font-size: ${fontSize.xLarge};

  @media screen and (min-width: 730px) {
    font-size: ${fontSize.oneHalfRem};
  }
`;
const PerkList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const PerkListItem = styled.li`
  display: flex;
  gap: 0.5rem;
  font-weight: 600;
  font-size: ${fontSize.large};

  @media screen and (min-width: 730px) {
    font-size: ${fontSize.xLarge};
  }
`;
const StyledCheckCircle = styled(CheckCircle)`
  color: ${colors.blue};
`;

const ProjectPerks = ({ data }) => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (window.innerWidth <= 730 && !mobile) {
        setMobile(true);
      } else if (window.innerWidth > 730 && mobile) {
        setMobile(false);
      }
    };

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, [mobile]);

  return (
    <Container>
      <Content>
        <Title>What does project do for you</Title>
        <PerksContainer>
          {data?.map((perk, index) => {
            const image = getImage(perk.image);
            return (
              <StyledCard
                elevation={mobile ? 2 : 0}
                reverse={index % 2 === 1}
                key={perk.title}
              >
                <GatsbyImage
                  image={image}
                  alt={perk.image.alt}
                  style={{ maxWidth: '550px' }}
                  quality={90}
                />
                <PerkListContainer>
                  <PerkTitle>{perk.title}</PerkTitle>
                  <PerkList>
                    {perk.content.split('|').map((item, index) => {
                      return (
                        <PerkListItem key={index}>
                          <StyledCheckCircle />
                          {item}
                        </PerkListItem>
                      );
                    })}
                  </PerkList>
                </PerkListContainer>
              </StyledCard>
            );
          })}
        </PerksContainer>
      </Content>
    </Container>
  );
};

export default ProjectPerks;
