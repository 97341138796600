import React from 'react';
import styled from 'styled-components/macro';

import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Divider from '@material-ui/core/Divider';

import HeaderSeo from '../../components/HeaderSeo';
import Layout from '../../components/Layout';

import AdvantagesOfGoget from '../../views/shared/AdvantagesOfGogetV2';
import Benefits from '../../views/Project/Benefits';
import ContactSection from '../../components/ContactSection';
import FAQSection from '../../views/shared/FAQ';
import TopHeader from '../../views/shared/TopHeaderV2';
import ProjectPerks from '../../views/Project/ProjectPerks';

import MetaImg from '../../assets/img/meta/business.png';

import colors from '../../assets/colors';
import Testimonials from '../../views/Project/Testimonials';

const ContactWrapper = styled.section`
  background-color: ${colors.projectBlueLight};
`;
const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: unset;
`;

const FAQData = [
  {
    question: 'What is GoGet project?',
    content: (
      <Text>
        GoGet Projects provides an end-to-end account management service where
        we handle recruitment, screening, and management of part-time staff for
        your required job scope.
      </Text>
    )
  },
  {
    question:
      'Is there a minimum project duration or number of headcount request to engage GoGet Project?',
    content: (
      <Text>
        The minimum requirement is a project duration of at least one month and
        a daily headcount of five.
      </Text>
    )
  },
  {
    question: 'The benefits of engaging with GoGet Projects?',
    content: (
      <Text>
        GoGet Projects offers customised recruitment solutions tailored to your
        specific needs and goals. You'll have a dedicated point of contact,
        access to our tech-enabled workforce management tools, and comprehensive
        end-to-end services, including screening, onboarding, and payment
        management. Our approach is adaptive and agile to meet your business
        requirements with a focus on account management.
      </Text>
    )
  },
  {
    question: 'What is the range of service/agent fee?',
    content: (
      <Text>
        We have project management fees which typically range between 20-30% of
        the project
      </Text>
    )
  },
  {
    question:
      'Will there be a dedicated project manager to my project and what is their role?',
    content: (
      <Text>
        Yes, you will have a dedicated project manager who will assist with job
        postings and oversee the management of GoGetters throughout the project.
      </Text>
    )
  },
  {
    question:
      'How would be the payment/salary to the workers handled by GoGet projects?',
    content: (
      <Text>
        Payment to GoGetters will be managed through the platform. You'll need
        to purchase GoGet credits, and the payments will be automatically
        deducted from your GoGet wallet.
      </Text>
    )
  }
];

const Project = props => {
  const {
    data: { datoCmsProject }
  } = props;

  const desktopImage = getImage(datoCmsProject?.bannerImageDesktop);
  const mobileImage = getImage(datoCmsProject?.bannerImageMobile);

  return (
    <>
      <HeaderSeo
        title="GoGet | On Demand Part Timers for Businesses"
        description="Our agile team powered by advanced in-house recruitment technology, stands ready to deliver seamless end-to-end solutions for your hiring needs"
        image={MetaImg}
        keywords="goget project, management, goget business plan, hire part time for business, temp agency, manpower recruitment agency"
        pathname={props.location.pathname}
      />
      <Layout>
        <TopHeader
          backgroundImg={
            <GatsbyImage
              image={desktopImage}
              loading="eager"
              style={{ height: '100%' }}
              objectPosition="left top"
              quality={90}
              alt={datoCmsProject?.bannerImageDesktop.alt}
            />
          }
          mobileBackgroundImg={
            <GatsbyImage
              image={mobileImage}
              loading="eager"
              style={{ height: '100%' }}
              quality={90}
              alt={datoCmsProject?.bannerImageMobile.alt}
            />
          }
          h4="Project"
          title={datoCmsProject?.headerTitle}
          description={datoCmsProject?.description}
          btn2={{
            text: 'TALK TO US',
            link: process.env.GATSBY_PWA_PATH
          }}
          secondary
          main
          showVideo
          hidePlay
        />
        <AdvantagesOfGoget
          textColor={colors.blue}
          data={datoCmsProject?.advantages}
        />
        <ProjectPerks data={datoCmsProject?.perks} />
        <Benefits data={datoCmsProject?.benefits} />
        <Testimonials data={datoCmsProject?.testimonials} />
        <Divider style={{ width: '80%', margin: '0 auto' }} />
        <FAQSection data={FAQData} />
        <ContactWrapper id="contact-us">
          <ContactSection
            title="Let us help grow your business!"
            subtext="Provide us with your details and we will get in touch."
            textBlack
            hideBackground
            projectPage
          />
        </ContactWrapper>
      </Layout>
    </>
  );
};

export default Project;

export const query = graphql`
  query ProjectQuery {
    datoCmsProject {
      headerTitle
      description
      bannerImageDesktop {
        gatsbyImageData(placeholder: BLURRED)
        alt
      }
      bannerImageMobile {
        gatsbyImageData(width: 600, placeholder: BLURRED)
        alt
      }
      advantages {
        image {
          gatsbyImageData(width: 200, placeholder: BLURRED)
          alt
        }
        header
        description
      }
      perks {
        image {
          gatsbyImageData(width: 1800, placeholder: BLURRED)
          alt
        }
        title
        content
      }
      benefits {
        description
        selfServe
        project
      }
      testimonials {
        headerImage {
          gatsbyImageData(width: 500, placeholder: BLURRED)
          alt
        }
        companyName
        jobTitle
        statistics {
          value
          description
        }
        challenge
        solution
        services {
          image {
            gatsbyImageData(width: 200, placeholder: BLURRED)
            alt
          }
          serviceType
        }
      }
      schemaMarkup
    }
  }
`;
